<template>
	<TheHeader hero="/images/screenshots/benefits-for-companies.png" :is-shadow="true">
		<template v-slot:title>
			ESOPs Ultimately Result in Increased Enterprise Efficiency. <span class="line relative inline-block text-theme">Verified.</span>
		</template>
		<template v-slot:description>
			Attract and incentivize talent. Lay the foundation of a committed workforce.
		</template>
	</TheHeader>
	<div>
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<small class="block mb-8 uppercase text-theme font-extrabold">
					Talents
				</small>
				<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
					Talent Acquisition
				</h3>
				<p class="mb-11 text-secondary leading-7 font-semibold">
					In order to attract the best talent in the field, the job offer has to be attractive and appealing. The best talent out there will be discerning when evaluating whether your company is where they want to spend their best years. Here’s where ESOPs serve as a boon to companies.
				</p>
				<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
					Talent Retention
				</h3>
				<p class="leading-7 text-secondary font-semibold">
					It is imperative for companies to ensure that their best talent stays with the company and ensure stability in the growth levels of the business. The ESOP vesting period or milestone principle is feature that ensures there is no high labour turnover since the options vest only after the specific vesting period time or milestone is achieved.
				</p>
			</div>
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/talent-acquisition-and-retention.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
		</section>
		<figure class="bg-wave-dark bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

    <div class="bg-primary">
        <section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/fundraising-and-cash-control.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
            <div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
                <small class="block mb-8 uppercase font-extrabold text-theme">
                	Money
                </small>
                <h3 class="2xl:text-4xl text-3xl font-black leading-normal text-white mb-4">
                    Fundraising
                </h3>
                <p class="mb-11 text-secondary leading-7 text-dark font-semibold">
                    An ESOP scheme reduces the risk for investors and is therefore a very powerful weapon for companies in the competitive struggle for investment. At later stages, founders will probably be even asked by the incoming investor to build an ESOP scheme to incentivise growth of the employee base.
                </p>
                <h3 class="2xl:text-4xl text-3xl font-black leading-normal text-white mb-4">
                    Cash Control
                </h3>
                <p class="text-secondary leading-7 text-dark font-semibold">
                    Instead of compensating top talent in the market with cash benefits only, they can be offered by a mix of cash and share on a company. The tendency of ESOPs as a wealth multiplier still attracts top talent.
                </p>
            </div>
        </section>
    </div>

	<div>
		<figure class="bg-wave-dark bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:-translate-y-1 -translate-y-0.5 rotate-180"></figure>
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<small class="block mb-8 uppercase text-theme font-extrabold">
					Company
				</small>
				<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
					Company Value
				</h3>
				<p class="text-secondary mb-11 leading-7 font-semibold">
					To see a substantial increase in a company value, there must be substantial growth to support it. And substantial growth is achieved on the back if employees are fully committed towards the success of the company. ESOPs instil in employees the fire and the drive to push the company to those limits since they are personally vested in the company and its value.
				</p>
				<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
					Company Culture
				</h3>
				<p class="text-secondary mb-6 leading-7 font-semibold">
					The employee participation has a powerful impact on the company culture. A positive, open culture can create trust and loyalty among employees, giving them passion for their job and a dedication to the company.
				</p>
				<p class="text-secondary leading-7 font-semibold">
					Employees who feel comfortable in the culture are more likely to be engaged in their jobs. ESOPs motivate the employees by the transparent rules, which minimizes the conflicts between shareholders of the employer and the employees.
				</p>
			</div>
			<kinesis-container tag="div" event="scroll" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/company-value-and-culture.svg"
					:strength="60"
					type="translate"
					class="xl:max-w-2xl md:max-w-md w-full inline-block"
				/>
			</kinesis-container>
		</section>
	</div>
</template>
<script>
	import TheHeader from "../components/TheHeader";

	export default {
		name: 'BenefitsForCompanies',
		components: {
			TheHeader,
		}
	}
</script>